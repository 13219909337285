@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HankRnd";
  src: url("fonts/HankRnd-Light.woff") format("woff"),
    url("fonts/HankRnd-Light.eot") format("eot");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "HankRnd";
  src: url("fonts/HankRnd-Regular.woff") format("woff"),
    url("fonts/HankRnd-Regular.eot") format("eot");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "HankRnd";
  src: url("fonts/HankRnd-Bold.woff") format("woff"),
    url("fonts/HankRnd-Bold.eot") format("eot");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "HankRnd";
  src: url("fonts/HankRnd-Black.woff") format("woff"),
    url("fonts/HankRnd-Black.eot") format("eot");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "HankRnd", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.react-datepicker__month-container {
  padding: 8px;
  font-family: "HankRnd", -apple-system, BlinkMacSystemFont, "Segoe UI" !important;
}

.react-datepicker-wrapper {
  width: inherit !important;
}

.react-datepicker__navigation-icon {
  top: 15px !important;
}

.react-datepicker-wrapper input {
  /* border: 1px solid #a5a7aa; */
  border-radius: 0.375rem;
  height: 42px;
  padding: 22px 16px;
  padding-right: 32px;
  font-size: 14px;
  font-weight: 500;
}

.react-datepicker__close-icon {
  right: 4px !important;
}

.react-datepicker__close-icon::after {
  background-color: rgb(37 99 235) !important;
}

table thead tr th:first-of-type {
  @apply sm:rounded-tl-lg;
}

table thead tr th:last-of-type {
  @apply sm:rounded-tr-lg;
}

table tbody tr:last-of-type td:first-of-type {
  @apply sm:rounded-bl-lg;
}

table tbody tr:last-of-type td:last-of-type {
  @apply sm:rounded-br-lg;
}

.style-bg {
  background-image: url("/src/assets/images/style_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.headerCell {
  cursor: pointer;
}
.headerCell:hover {
  opacity: 0.7;
}

.fixedCell {
  position: sticky !important;
  left: 0;
  position: sticky;
  z-index: 1;
  opacity: 1;
  background: #eff6ff !important;
  box-shadow: -2px 0px 2px 0px #dfdff0 !important;
}
.fixedCell:hover {
  opacity: 1 !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.template {
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 88px);
}

.template > table {
  height: 100%;
  min-height: calc(100vh - 88px);
}

.noActive {
  display: flex;
  min-height: calc(100vh - 88px);
  align-items: center;
  justify-content: center;
}

.noActive > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 500px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__header {
  min-height: 90px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  background: none !important;
  border-bottom: none !important;
}

.react-datepicker-popper {
  /* transform: none !important; */
  /* position: relative !important; */
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day {
  height: 28px !important;
  width: 28px !important;
}

.react-datepicker__day-name {
  height: 28px !important;
  width: 28px !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  background-color: #2563eb !important;
}

.react-datepicker-popper {
  z-index: 300 !important;
}

.reactMarkdown a {
  text-decoration: underline;
}

/* Drag and Drop Data table  */
.drag-handle {
  height: 1rem;
  left: 10%;
  position: absolute;
}

.drag-handle svg {
  width: 100%;
  height: 100%;
}

.styled-static-table-row {
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  outline: #2563eb solid 1px;
}

.table-switch-status > div {
  justify-content: center;
}

/* Deadline Picker */
.option-selector {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
}

.option.selected {
  border: 2px solid #2563eb;
}

.label {
  font-weight: bold;
}

.description {
  color: #666;
}

#fulfillment-time {
  width: 100%;
  padding: 7px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
